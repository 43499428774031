import React from "react";

import styled, { keyframes } from "styled-components";

import Currency from "../Image/currencyLogo.png";

const spin = keyframes`

  from {

    transform: rotate(0deg);

  }

  to {

    transform: rotate(360deg);

  }

`;

const rotateTwice = keyframes`

  from {

    transform: rotate(720deg);

  }

  to {

    transform: rotate(0deg);

  }

`;

const LoaderContainer = styled.div`
  display: flex;

  align-items: center;

  justify-content: center;

  align-items: center;

  flex-direction: column;

  height: 100vh;
`;

const Image = styled.img`
  width: 24px;

  height: 35px;

  margin-bottom: -63px;

  ${"" /* animation: ${rotateTwice} 4s linear infinite;  */}
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;

  border-top: 4px solid #ff6600;

  border-radius: 50%;

  width: 80px;

  height: 80px;

  animation: ${spin} 2s linear infinite;
`;

const Loader = () => {
  return (
    <LoaderContainer>
      <Image src={Currency} alt="Loading" />

      <Spinner />
    </LoaderContainer>
  );
};

export default Loader;
