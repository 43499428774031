import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Box,
  Paper,
  Divider,
  useMediaQuery,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ListItemIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { constant } from "../Constant/const";
import { useDispatch, useSelector } from "react-redux";
import { showAbout, showMission } from "../Reducer/Reducer";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../Image/Logo.svg";
import {
  decreaseFontSize,
  handlePhoneBar,
  increaseFontSize,
  resetFontSize,
} from "../Reducer/Reducer";

const Navbar = ({ setOpenMenu }) => {
  const isTabScreen = useMediaQuery("(max-width:1200px)");
  const isMediumScreen = useMediaQuery("(max-width:720px)");
  const { t, i18n } = useTranslation();
  const C_Language =
    (i18n.language === "en" && "English") ||
    (i18n.language === "hn" && "हिंदी") ||
    (i18n.language === "odi" && "ଓଡିଆ") ||
    (i18n.language === "ben" && "বাংলা");
  console.log(C_Language, "language");
  const dispatch = useDispatch();
  const openMenu = useSelector((state) => state.reducer.Navbar.phoneBar);
  const navigate = useNavigate();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [LanguageanchorEl, setLanguageAnchorEl] = useState(null);

  // const [LanguageValue, setLanguageValue] = useState(C_Language);
  const [LanguageData, SetLanguageData] = useState([...constant?.Language]);

  useEffect(() => {
    if (openMenu) {
      // Enable scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Disable scrolling
      document.body.style.overflow = "auto";
    }
  }, [openMenu]);

  const fontStyle = {
    fontSize: "2rem",
    fontWeight: "600",
    fontFamily: "Inter,sans-serif",
    textTransform: "capitalize",
    overflow: "hidden",
    display: "flex",
    letterSpacing: "-.3px",
    width: "60vw",
    flexDirection: "row", // Display icon at the end
    justifyContent: "space-between", // To separate text and icon
    alignItems: "center", // Align items vertically
  };

  const subMenuStyle = {
    fontSize: "1.8rem",
    fontWeight: "400",
    height: "38px",
    fontFamily: "Inter,sans-serif",
    pl: 3,
    overflow: "hidden",
    minWidth: "150px",
    marginTop: -1,
    // "&:hover": {
    //   backgroundColor: "#FF6600",
    //   color: "#fff",

    // },
  };

  const handleMenuOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  // ----: ANIL CODE :-----
  const handleMenuClose1 = (name) => {
    setAnchorEl1(null);
    if (name == "Banking Service") {
      dispatch(handlePhoneBar());
      navigate("/BankingServices");
      window.scrollTo({ top: 0 });
    } else if (name == "Payment Service") {
      dispatch(handlePhoneBar());
      navigate("/PaymentServices");
      window.scrollTo({ top: 0 });
    }else if (name == "Utility Service") {
      dispatch(handlePhoneBar());
      navigate("/utility.html");
      window.scrollTo({ top: 0 });
    }else if (name == "Devices") {
      dispatch(handlePhoneBar());
      navigate("/Devices");
      window.scrollTo({ top: 0 });
    } else if (name == "Insurance Service") {
      dispatch(handlePhoneBar());
      navigate("/insuranceService");
      window.scrollTo({ top: 0 });
    } else if (name == "Travel Service") {
      dispatch(handlePhoneBar());
      navigate("/travelService");
      window.scrollTo({ top: 0 });
    } else if (name == "Partner Service") {
      dispatch(handlePhoneBar());
      navigate("/partnerService");
      window.scrollTo({ top: 0 });
    }
  };

  // ###########################

  const handleMenuOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMenuClose2 = () => {
    setAnchorEl2(null);
    dispatch(handlePhoneBar());
  };

  const handleMenuOpen3 = (event) => {
    setAnchorEl3(event.currentTarget);
    // navigate("/About");
    // dispatch(showAbout())
  };

  const handleMenuClose3 = (name) => {
    setAnchorEl3(null);
    // navigate("/About");
    window.scrollTo({ top: 0 });

    if (name == "About us") {
      navigate("/AboutUs");
    } else if (name == "Mission/Vision") {
      navigate("/MissionAndVision");
    } else if (name == "Management Team") {
      navigate("/Management");
    }
  };

  const handleLanguageOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = (val, name) => {
    setLanguageAnchorEl(null);
    dispatch(handlePhoneBar());
    if (val && name) {
      i18n.changeLanguage(val);
      localStorage.setItem("Language", val);
      // setLanguageValue(name);
    }
  };

  // console.log(anchorEl, "anker value");

  return (
    <Paper
      sx={{
        height: "auto",
        width: openMenu ? "80%" : "0%",
        height: "100vh",
        position: "fixed",
        top: "0px",
        zIndex: "1",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        border: "none",
        zIndex: 1200,
        overflow: "hidden",
        transition: "width 0.3s ease",
      }}
    >
      {/* <MenuIcon sx={{fontSize:'25px'}}/> */}

      <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
        <img src={logo} className="headerLogo" style={{ marginLeft: "10%" }} />
        <CloseIcon
          sx={{ fontSize: "25px", mt: 0.5 }}
          onClick={() => dispatch(handlePhoneBar())}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "100%" }}>
          <AppBar
            position="static"
            elevation={0}
            sx={{ color: "#1a1a1d", bgcolor: "#fff" }}
          >
            <Toolbar
              sx={{
                justifyContent: "start",
                backgroundColor: "#fff",
                flexDirection: "column ",
                alignItems: "start",
              }}
            >
              {/* <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
        <MenuIcon />
      </IconButton> */}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  ml: 4,
                }}
              >
                <Button
                  color="inherit"
                  sx={fontStyle}
                  onClick={() => {
                    navigate("/");
                    window.scrollTo({ top: 0 });

                    dispatch(handlePhoneBar());
                  }}
                  disableRipple
                >
                  {t("Home")}
                </Button>

                <Divider sx={{ width: "100%" }} />
                {/*services  */}
                <Button
                  color="inherit"
                  sx={fontStyle}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={handleMenuOpen1}
                  disableRipple
                >
                  {t("Services")}
                </Button>
                <Menu
                  anchorEl={anchorEl1}
                  open={Boolean(anchorEl1)}
                  onClose={handleMenuClose1}
                  elevation={0}
                  anchorOrigin={{
                    vertical: "bottom",

                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",

                    horizontal: "center",
                  }}
                  PaperProps={{
                    sx: {
                      boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.2)",
                      width: "60%",
                    },
                  }}
                  disableScrollLock={true}
                >
                  <MenuItem
                    onClick={() => handleMenuClose1("Banking Service")}
                    sx={subMenuStyle}
                  >
                    {t("Banking Services")}
                  </MenuItem>
                  <Divider sx={{ ml: 1.5, mr: 1.5 }} />
                  <MenuItem
                    onClick={() => handleMenuClose1("Payment Service")}
                    sx={subMenuStyle}
                  >
                    {t("Payment Services")}
                  </MenuItem>
                  <Divider sx={{ ml: 1.5, mr: 1.5 }} />
                  <MenuItem
                    onClick={() => handleMenuClose1("Utility Service")}
                    sx={subMenuStyle}
                  >
                    {t("Utility Payments")}
                  </MenuItem>
                  <Divider sx={{ml:1.5,mr:1.5}} />
                   <MenuItem
                    onClick={() => handleMenuClose1("Devices")}
                    sx={subMenuStyle}
                  >
                    Devices
                  </MenuItem>
              {/*  <Divider sx={{ml:1.5,mr:1.5}} />
                  <MenuItem
                    onClick={() => handleMenuClose1("Travel Service")}
                    sx={subMenuStyle}
                  >
                    Travel
                  </MenuItem>
                  <Divider sx={{ml:1.5,mr:1.5}} />
                  <MenuItem
                    onClick={() => handleMenuClose1("Partner Service")}
                    sx={subMenuStyle}
                  >
                    Partner Services
                  </MenuItem> */}
                </Menu>
                <Divider sx={{ width: "100%" }} />
                {/* media Event  for now content is not ready when content ready jus un comment the button and menu*/}
                {/* <Button
                  color="inherit"
                  sx={fontStyle}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={handleMenuOpen2}
                >
                  {t("Media & Event")}
                </Button>
                <Menu
                  anchorEl={anchorEl2}
                  open={Boolean(anchorEl2)}
                  onClose={handleMenuClose2}
                  elevation={0}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{ overflow: "hidden" }}
                  PaperProps={{
                    sx: {
                      boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  disableScrollLock={true}
                >
                  <MenuItem onClick={handleMenuClose2} sx={subMenuStyle}>
                    {t("Press Conferences")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose2} sx={subMenuStyle}>
                    {t("Product Launches")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose2} sx={subMenuStyle}>
                    {t("Award Ceremonies")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose2} sx={subMenuStyle}>
                    {t("Photo Gallery")}
                  </MenuItem>
                </Menu> */}
                {/* About us */}

                <Button
                  color="inherit"
                  sx={fontStyle}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={handleMenuOpen3}
                  disableRipple
                >
                  {t("Who We Are")}
                </Button>
                <Menu
                  anchorEl={anchorEl3}
                  open={Boolean(anchorEl3)}
                  onClose={handleMenuClose3}
                  elevation={0}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{ overflow: "hidden" }}
                  PaperProps={{
                    sx: {
                      boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  disableScrollLock={true}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose3("About us");
                    }}
                    sx={subMenuStyle}
                  >
                    {t("About us")}
                  </MenuItem>
                  {/* <Divider sx={{ ml: 1.5, mr: 1.5 }} />
                  <MenuItem
                    onClick={() => {
                      handleMenuClose3("Management Team");
                    }}
                    sx={subMenuStyle}
                  >
                    {t("Management Team")}
                  </MenuItem> */}
                  <Divider sx={{ ml: 1.5, mr: 1.5 }} />
                  <MenuItem
                    onClick={() => {
                      handleMenuClose3("Mission/Vision");
                    }}
                    sx={subMenuStyle}
                  >
                    {t("Mission/Vision")}
                  </MenuItem>
                </Menu>
                <Divider sx={{ width: "100%" }} />
                <Button
                  color="inherit"
                  sx={fontStyle}
                  onClick={() => {
                    navigate("/Carrer");
                    window.scrollTo({ top: 0 });

                    dispatch(handlePhoneBar());
                  }}
                  disableRipple
                >
                  {t("Careers")}
                </Button>
                <Divider sx={{ width: "100%" }} />
                <Button
                  color="inherit"
                  sx={fontStyle}
                  onClick={() => {
                    navigate("/Contact");
                    window.scrollTo({ top: 0 });

                    dispatch(handlePhoneBar());
                  }}
                  disableRipple
                >
                  {t("Contact Us")}
                </Button>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  backgroundColor: "whitesmoke",
                  borderRadius: "10px",
                  width: "120px",
                  height: "40px",
                  position: "absolute",
                  ml: 5,
                  top: "78vh !important",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    color: "#161971",
                    fontWeight: "400",
                    fontSize: "1.8rem",
                    color: "#1a1a1d",
                    m: 0.5,
                    cursor: "pointer",
                    fontFamily: "Inter,sans-serif",
                  }}
                  onClick={() => {
                    dispatch(decreaseFontSize());
                  }}
                >
                  -A
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    color: "#161971",
                    fontWeight: "500",

                    fontSize: "2rem",
                    color: "#1a1a1d",
                    m: 0.5,
                    cursor: "pointer",
                    fontFamily: "Inter,sans-serif",
                  }}
                  onClick={() => {
                    dispatch(resetFontSize());
                  }}
                >
                  A
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    color: "#161971",
                    fontWeight: "500",

                    fontSize: "2.2rem",
                    color: "#1a1a1d",
                    m: 0.5,
                    cursor: "pointer",
                    fontFamily: "Inter,sans-serif",
                  }}
                  onClick={() => {
                    dispatch(increaseFontSize());
                  }}
                >
                  A+
                </Typography>
              </Box>

              <Button
                sx={{
                  color: "gray",
                  fontSize: "1.6rem",
                  textTransform: "capitalize",
                  ml: 4.9,
                  position: "absolute",

                  top: "85vh !important",
                  pb: 4,
                }}
                endIcon={<ArrowDropDownIcon sx={{ ml: -0.4, mt: -0.3 }} />}
                onClick={handleLanguageOpen}
                disableRipple
              >
                {t("Language")} : {C_Language}
              </Button>
              <Menu
                anchorEl={LanguageanchorEl}
                open={Boolean(LanguageanchorEl)}
                onClose={() => handleLanguageClose(false, false)}
                elevation={0}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  sx: {
                    boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.2)",
                  },
                }}
                disableScrollLock={true}
              >
                {LanguageData.map((ele) => (
                  <MenuItem
                    key={ele.id}
                    onClick={() => handleLanguageClose(ele.value, ele.name)}
                    sx={subMenuStyle}
                  >
                    {ele.name}
                  </MenuItem>
                ))}
              </Menu>
            </Toolbar>
          </AppBar>
        </Box>
      </Box>
    </Paper>
  );
};

export default Navbar;
