import React from "react";

// import {  makeStyles } from '@mui/styles';

import { Box, Container, Typography, useMediaQuery } from "@mui/material";

import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";

import twitterIcon from "../Image/twitterx.svg";

import YouTubeIcon from "@mui/icons-material/YouTube";

import InstagramIcon from "@mui/icons-material/Instagram";

import logo from "../Image/sevenpay-logo (1) (1).svg";

import Button from "@mui/material/Button";

import android from "../Image/Android.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Footer = () => {
  // const classes = useStyles();
  const FontSizeValue = useSelector((state) => state.reducer.Style.fontSize);
  const isSmallScreen = useMediaQuery("(max-width:720px)");

  return (
    <>
      <Box>
        <Box>
          <div className={"footer"}>
            {/* <Container maxWidth="lg"> */}
            <div
              className={"row"}
              style={{
                paddingTop: "30px",
                paddingLeft: isSmallScreen ? "1rem" : 0,
              }}
            >
              <div className={"column"} style={{ marginRight: "3rem" }}>
                <img
                  src={logo}
                  alt="sevenpayImage"
                  height="40px"
                  className={"columnImage"}
                />

                <Typography
                  // variant="h6"
                  color="textPrimary"
                  className={"text"}
                  sx={{
                    mt: "20px",
                    fontSize: `${(1.6 + FontSizeValue).toFixed(
                      2
                    )}rem !important`,
                  }}
                >
                  Sevenpay is India's leading Rural <br /> Fintech company
                  empowering <br /> merchants / nanopreneurs
                </Typography>

                {/* <Button variant="contained" className={classes.googleplay} height="30px" sx={{mt:"20px"}}> */}
                <a
                      href="https://play.google.com/store/apps/details?id=com.sevenpay&pcampaignid=web_share"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "black", contain: "paint" }}
                    >
                <img
                  src={android}
                  alt="googleplaylogo"
                  height="35px"
                  style={{ marginTop: "20px" }}
                /></a>

                {/* </Button> */}
              </div>

              <div className={"column"} style={{ marginLeft: "1rem" }}>
                <Typography
                  gutterBottom
                  className={"textHeader"}
                  sx={{
                    fontSize: `${(1.8 + FontSizeValue).toFixed(
                      2
                    )}rem !important`,
                    fontWeight: 600,
                  }}
                >
                  Important Links
                </Typography>

                <Typography
                  // variant="body2"
                  color="textSecondary"
                  sx={{ mt: "3rem", fontWeight: 500 }}
                >
                  <Link
                    to="/AboutUs"
                    className={"link"}
                    style={{ fontSize: "1.6rem" }}
                  >
                    About Us
                  </Link>
                </Typography>

                {/* <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: "20px" }}
                >
                  <a href="https://www.example.com" className={"link"}>
                    FAQ's
                  </a>
                </Typography> */}
                <Typography
                  // variant="body2"
                  color="textSecondary"
                  sx={{ mt: "2rem", fontWeight: 500 }}
                >
                  <Link
                    to="/Carrer"
                    className={"link"}
                    style={{ fontSize: "1.6rem" }}
                  >
                    Career
                  </Link>
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: "20px" }}
                >
                  <Link
                    to="/privacy_policy.html"
                    className={"link"}
                    style={{ fontSize: "1.6rem" }}
                  >
                    Privacy Policy
                  </Link>
                </Typography>
                {/* 
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: "20px" }}
                >
                  <a href="https://www.example.com" className={"link"}>
                    Terms of Service
                  </a>
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: "20px" }}
                >
                  <a href="https://www.example.com" className={"link"}>
                    Refund & Cancellation Policy
                  </a>
                </Typography> */}
              </div>

              <div
                className={"column"}
                style={{ marginRight: "3rem", marginLeft: "2rem" }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  className={"textHeader"}
                  sx={{
                    fontSize: `${(1.8 + FontSizeValue).toFixed(
                      2
                    )}rem !important`,
                    fontWeight: 600,
                  }}
                >
                  Legal
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: "3rem" }}
                >
                  <Link
                    to="/termsAndConditions"
                    className={"link"}
                    style={{
                      fontSize: `${(1.6 + FontSizeValue).toFixed(2)}rem `,
                    }}
                  >
                    Terms & Conditions
                  </Link>
                </Typography>

              {/*  <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: "20px" }}
                >
                  <Link
                    to="/grievanceAndRedressal"
                    className={"link"}
                    style={{
                      fontSize: `${(1.6 + FontSizeValue).toFixed(2)}rem `,
                    }}
                  >
                    Grievance & Redressal Policy
                  </Link>
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: "10px" }}
                >
                  <a href="https://www.example.com" className={"link"}>
                    Public Disclosure
                  </a>
                  </Typography>*/}


                
              </div>

              <div
                className={"column"}
                style={{ marginRight: "0px !important", marginLeft: "4rem" }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  className={"textHeader"}
                  sx={{
                    fontSize: `${(1.8 + FontSizeValue).toFixed(
                      2
                    )}rem !important`,
                    fontWeight: 600,
                  }}
                >
                  Contact Us
                </Typography>

                <div>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontSize: `${(1.6 + FontSizeValue).toFixed(
                        2
                      )}rem !important`,
                      color: "#46466D",
                      fontWeight: 600,
                      marginTop: "2.5rem",
                    }}
                  >
                    Mailing Address
                  </Typography>

                  <Typography
                    color="textSecondary"
                    sx={{
                      fontSize: `${(1.6 + FontSizeValue).toFixed(
                        2
                      )}rem !important`,
                    }}
                  >
                    7X Fintech Pvt Limited, DCB 620, DLF CYBERCITY, Infocity,
                    <br /> Patia, Bhubaneswar, Odisha - 751024
                  </Typography>
                </div>

                <div>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontSize: `${(1.6 + FontSizeValue).toFixed(
                        2
                      )}rem !important`,
                      mt: "2rem",
                      color: "#46466D",
                      fontWeight: 600,
                    }}
                  >
                    Email
                  </Typography>

                  <Typography
                    variant="h6"
                    color="textSecondary"
                    sx={{
                      fontSize: `${(1.6 + FontSizeValue).toFixed(
                        2
                      )}rem !important`,
                    }}
                  >
                    support@bankify.money
                  </Typography>
                </div>

                <div>
                  <Typography
                    variant="h6"
                    gutterBottom
                    marginTop="5px"
                    sx={{
                      fontSize: `${(1.6 + FontSizeValue).toFixed(
                        2
                      )}rem !important`,
                      mt: "2rem",
                      color: "#46466D",
                      fontWeight: 600,
                    }}
                  >
                    Toll Free Number
                  </Typography>

                  <div className={"toolText"}>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      marginRight="7rem"
                      sx={{
                        fontSize: `${(1.6 + FontSizeValue).toFixed(
                          2
                        )}rem !important`,
                      }}
                    >
                      Support{" "}
                      <span style={{ display: "block" }}>98278 32784</span>
                    </Typography>

                    {/* <Typography
                      variant="h6"
                      color="textSecondary"
                      sx={{ fontSize: "1.6rem" }}
                    >
                      Join
                      <span style={{ display: "block",  }}>
                        1800 345 7711
                      </span>
                    </Typography> */}
                  </div>
                </div>

                <div
                  className={"icon"}
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ marginRight: "5px" }}>
                    <a
                      href="https://www.facebook.com/people/Sevenpay/100095334407948/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "black", contain: "paint" }}
                    >
                      <FacebookRoundedIcon fontSize="large" />
                    </a>
                  </div>

                  <div style={{ marginRight: "5px",marginTop:isSmallScreen?'0px':'2px' }}>
                    <a
                      href="https://twitter.com/Sevenpay01"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "black", contain: "paint" }}
                    >
                      <img src={twitterIcon} alt='twitter' width={isSmallScreen?12:18} />
                    </a>
                  </div>

                  <div style={{ marginRight: "5px" }}>
                    <a
                      href="https://youtube.com/@Sevenpay_india"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "black", contain: "paint" }}
                    >
                      <YouTubeIcon fontSize="large" />
                    </a>
                  </div>

                  <div style={{ marginRight: "5px" }}>
                    <a
                      href="https://instagram.com/sevenpay.in?igshid=MmIzYWVlNDQ5Yg=="
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "black", contain: "paint" }}
                    >
                      <InstagramIcon fontSize="large" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* </Container> */}
          </div>
        </Box>
      </Box>

      <div className={"bottomRow"}>
        <Typography variant="body1">
          Copyright © 2023-24 Bankify.money Limited. All rights reserved.
        </Typography>
      </div>
    </>
  );
};

export default Footer;
