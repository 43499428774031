import { createSlice } from "@reduxjs/toolkit";

const c_Data = [
  {
    id: 1,

    applyFor: ".Net core - Backend Developer",

    address: "Bhubaneswar, Odisha",

    shortSummery: `Write high-quality software using best practices, coding standards, and frameworks. Write new code and maintain existing /re-usable code. Write and run automated test scripts to ensure high-quality work...`,

    description: [
      {
        id: Number(new Date()),

        name: "Write high-quality software using best practices, coding standards, and frameworks",
      },

      {
        id: Number(new Date()),

        name: "Write new code and maintain existing /re-usable code",
      },

      {
        id: Number(new Date()),

        name: "Write and run automated test scripts to ensure high-quality work",
      },

      {
        id: Number(new Date()),

        name: "Develop web-based software, web services and integrate with 3rd party web services",
      },

      {
        id: Number(new Date()),

        name: "Ship working code by automating build releases that align with sprint and release iterations",
      },

      {
        id: Number(new Date()),

        name: "Should maintain quality in deliverables. ",
      },
    ],

    Skill: [
      {
        id: Number(new Date()),

        name: "Experienced in the following technology stack: .NET Framework 5/6, .Net Core, Web API, Entity Framework, LINQ, TPL (Task processing library), Threading.",
      },

      {
        id: Number(new Date()),

        name: "Framework, SQL Server, JSON, XML, Web API ",
      },

      {
        id: Number(new Date()),

        name: "Experience writing testable code using unit tests (MS Test) and mock frameworks.",
      },

      {
        id: Number(new Date()),

        name: "Experience writing testable code using unit tests (MS Test) and mock frameworks.",
      },

      {
        id: Number(new Date()),

        name: "Experience with client-side development using HTML/CSS/JS/JQuery",
      },

      {
        id: Number(new Date()),

        name: "Good to have experience with a client-side JavaScript framework such as React JS, Bootstrap, AJAX",
      },
    ],

    openPostion: "1",

    postDate: "17/08/2023",

    designation: "Software Engineer",

    Department: "Technology - Enterprise",
  },

  {
    id: 2,

    applyFor: "React JS - Frontend Developer",

    address: "Bhubaneswar, Odisha",

    shortSummery: `Developing new user-facing features using React.js. Building reusable components and front-end libraries for future use.Optimizing components for maximum performance across a vast array of web-capable devices and browsers... `,

    description: [
      {
        id: Number(new Date()),

        name: "Developing new user-facing features using React.js ",
      },

      {
        id: Number(new Date()),

        name: "Building reusable components and front-end libraries for future use",
      },

      {
        id: Number(new Date()),

        name: "Translating designs and wireframes into high quality code",
      },

      {
        id: Number(new Date()),

        name: "Optimizing components for maximum performance across a vast array of web-capable devices and browsers",
      },
    ],

    Skill: [
      {
        id: Number(new Date()),

        name: "Must have 4+ years of experiance.",
      },

      {
        id: Number(new Date()),

        name: "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model. ",
      },

      {
        id: Number(new Date()),

        name: "Thorough understanding of React.js and its core principles.",
      },

      {
        id: Number(new Date()),

        name: "Experience with popular React.js workflows (such as Flux or Redux).",
      },

      {
        id: Number(new Date()),

        name: "Experience with data structure libraries (e.g., Immutable.js).",
      },

      {
        id: Number(new Date()),

        name: "Knowledge of isomorphic React is a plus",
      },

      {
        id: Number(new Date()),

        name: "Familiarity with RESTful APIs",
      },

      {
        id: Number(new Date()),

        name: "Knowledge of modern authorization mechanisms, such as JSON Web Token",
      },

      {
        id: Number(new Date()),

        name: "Familiarity with modern front-end build pipelines and tools  ",
      },
      {
        id: Number(new Date()),

        name: "Experience with common front-end development tools such as Babel, Webpack, NPM, etc.  ",
      },

      {
        id: Number(new Date()),

        name: "Ability to understand business requirements and translate them into technical requirements    ",
      },

      {
        id: Number(new Date()),

        name: "A knack for benchmarking and optimization ",
      },

      {
        id: Number(new Date()),

        name: "Familiarity with code versioning tools {{such as Git, SVN, and Mercurial}} ",
      },
    ],

    openPostion: "1",

    postDate: "17/08/2023",

    designation: "React js Developer",

    Department: "Technology - Enterprise",
  },

  {
    id: 3,

    applyFor: "Android Developer",

    address: "Bhubaneswar, Odisha",

    shortSummery: `Design and build advanced applications for the Android platform.Collaborate with cross-functional teams to define, design, and ship new features.Work with outside data sources and APIs. Unit-test code for robustness, including edge cases, usability, and general reliability... `,

    description: [
      {
        id: Number(new Date()),

        name: "Design and build advanced applications for the Android platform   ",
      },

      {
        id: Number(new Date()),

        name: "Collaborate with cross-functional teams to define, design, and ship new features",
      },

      {
        id: Number(new Date()),

        name: "Work with outside data sources and APIs",
      },

      {
        id: Number(new Date()),

        name: "Unit-test code for robustness, including edge cases, usability, and general reliability",
      },

      {
        id: Number(new Date()),

        name: "Work on bug fixing and improving application performance ccontinuously discover, evaluate, and implement new technologies to maximize development efficiency",
      },
    ],

    Skill: [
      {
        id: Number(new Date()),

        name: "4 years plus of hands-on experience in Java, Kotlin and Android application development.   ",
      },

      {
        id: Number(new Date()),

        name: "Knowledge of UI frameworks (Android) , Android SDK ",
      },

      {
        id: Number(new Date()),

        name: "Experience working with remote data via REST, JSON and XML   ",
      },

      {
        id: Number(new Date()),

        name: "Proven knowledge of Google’ Android UI design principles, patterns, and best practices  ",
      },

      {
        id: Number(new Date()),

        name: "Experience targeting multiple devices, both phones and tablets, from multiple manufacturers and across all OS versions. ",
      },

      {
        id: Number(new Date()),

        name: "Familiarity with cloud message APIs and push notifications ",
      },

      {
        id: Number(new Date()),

        name: "A knack for benchmarking and optimization ",
      },

      {
        id: Number(new Date()),

        name: "Proficient understanding of code versioning tools, such as Git.  ",
      },

      {
        id: Number(new Date()),

        name: "Familiarity with continuous integration",
      },
    ],

    openPostion: "1",

    postDate: "17/08/2023",

    designation: "Android Developer",

    Department: "Technology - Enterprise",
  },

  {
    id: 4,

    applyFor: "Legal Advisor",

    address: "Bhubaneswar, Odisha",

    shortSummery: `Legal dept. take care to draft and review Legal documents / Agreements such as employment agreement, Contract Agreement, Partnership Agreement, Intellectual Property Agreement etc. To take care the needs/ compliance of local authorities i.e., ESI, EPF, Labor office, Municipality and any other local administration...`,

    description: [
      {
        id: Number(new Date()),

        name: "Legal dept. take care to draft and review Legal documents / Agreements such as employment agreement, Contract Agreement, Partnership Agreement, Intellectual Property Agreement etc.",
      },

      {
        id: Number(new Date()),

        name: "To take care the needs/ compliance of local authorities i.e., ESI, EPF, Labor office, Municipality and any other local administration.",
      },

      {
        id: Number(new Date()),

        name: " To take care the local Police station issues.",
      },

      {
        id: Number(new Date()),

        name: "Provide legal assistance to the Human resources team for employment matters and contract to draft for employment and other relating matters.",
      },

      {
        id: Number(new Date()),

        name: "Prepare & send legal notices to clients, filing legal or civil cases through panel advocates against debtors/Incubates defaulting payments.",
      },

      {
        id: Number(new Date()),

        name: "To take care of due diligence report preparation through Panel Advocates in case setup of new offices or branches etc. and prepare House Rent Agreement.",
      },
    ],

    Skill: [
      {
        id: Number(new Date()),

        name: "Must have 3+ years of experiance.",
      },

      {
        id: Number(new Date()),

        name: " Timely Compliance of all customer notices / grievances or Advocate notices raised against the Company",
      },

      {
        id: Number(new Date()),

        name: "Closely monitoring all the statutory / legal compliance with Govt. like RBI/ Ombudsman  ",
      },

      {
        id: Number(new Date()),

        name: " Taking care of Civil, Criminal cases filed against company before Dist. Consumer Forum/ State Commissions/ National Commissions/ High Courts/ PLA matters through panel Advocates.  ",
      },

      {
        id: Number(new Date()),

        name: "Coordinate with Senior Advocates for legal advices if needed in any special cases. ",
      },

      {
        id: Number(new Date()),

        name: "To take care the issues relating fraudulent transactions and Payment Gateway related matters.",
      },

      {
        id: Number(new Date()),

        name: "Maintaining various legal MIS, maintenance of Legal Records & Up keeping of legal files both in physically and in computer systems.",
      },
    ],

    openPostion: "1",

    postDate: "17/08/2023",

    designation: "Legal Advisor",

    Department: "Technology - Enterprise",
  },
];

const initialState = {
  hideLanguagePage: {
    isOpen: true,
  },

  Navbar: {
    phoneBar: false,
  },

  Style: {
    fontSize: 0,
  },

  carrer: {
    carrerData: c_Data,
  },

  jobDescription: {
    jobDescriptionData: {},
  },
};

export const SevenPayReducer = createSlice({
  name: "Sevenpay",

  initialState,

  reducers: {
    hideLanguage: (state, action) => {
      state.hideLanguagePage.isOpen = false;
    },

    handlePhoneBar: (state, action) => {
      state.Navbar.phoneBar = !state.Navbar.phoneBar;
    },

    increaseFontSize: (state, action) => {
      state.Style.fontSize = state.Style.fontSize + 0.2;
    },

    decreaseFontSize: (state, action) => {
      state.Style.fontSize = state.Style.fontSize - 0.2;
    },

    resetFontSize: (state, action) => {
      state.Style.fontSize = 0;
    },

    filterCarrerData: (state, action) => {
      state.jobDescription.jobDescriptionData = state.carrer.carrerData.filter(
        (ele) => ele.id === action.payload
      );
    },
  },

  extraReducers: {},
});

// Action creators are generated for each case reducer function

export const {
  hideLanguage,
  handlePhoneBar,
  increaseFontSize,
  decreaseFontSize,
  resetFontSize,
  filterCarrerData,
} = SevenPayReducer.actions;

export default SevenPayReducer.reducer;

// getCartList
