import React, { useState } from "react";

import {
  AppBar,
  Toolbar,
  Grid,
  Box,
  Typography,
  Button,
  useMediaQuery,
  Tooltip,
} from "@mui/material";

import CallIcon from "@mui/icons-material/Call";

import logo from "../Image/sevenpay-logo (1) (1).svg";

import Nav from "./Nav";

import { FormControl, Select, MenuItem } from "@mui/material";

import { useTranslation } from "react-i18next";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import MenuIcon from "@mui/icons-material/Menu";

import NavBar from "./PhoneNav";

import { useDispatch, useSelector } from "react-redux";

import {
  decreaseFontSize,
  handlePhoneBar,
  increaseFontSize,
  resetFontSize,
} from "../Reducer/Reducer";

const Header = () => {
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = React.useState("Agent");

  const [openMenu, setOpenMenu] = useState("false");

  const isLargeScreen = useMediaQuery("(max-width:1200px)");

  const isMediumScreen = useMediaQuery("(max-width:720px)");

  const isSmallScreen = useMediaQuery("(max-width:490px)");

  const isPhoneScreen = useMediaQuery("(max-width:720px)");

  const FontSizeValue = useSelector((state) => state.reducer.Style.fontSize);

  const { t } = useTranslation();

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  console.log(FontSizeValue, "FontSizeValue");
  return (
    <Box sx={{ position: "relative" }}>
      <AppBar
        className="main-header"
        elevation={0}
        position="fixed"
        sx={{
          maxWidth: "100% !important",

          overflow: "hidden",

          backgroundColor: "#fff",
        }}
      >
        <Toolbar className="nav">
          {/* menu bar for responsive */}

          {isMediumScreen && (
            <Box
              sx={{
                display: "flex",

                justifyContent: "start",

                alignItems: "start",
              }}
            >
              <MenuIcon
                sx={{
                  fontSize: isSmallScreen ? "23px" : "25px",

                  mt: -1,

                  mr: 0.5,
                }}
                onClick={() => dispatch(handlePhoneBar())}
              />
            </Box>
          )}

          <Box
            sx={{
              display: "flex",

              justifyContent: "center",

              width: "100%",

              alignItems: "center",
            }}
          >
            <Grid container sx={{ maxWidth: isLargeScreen ? "90vw" : "75vw" }}>
              <Grid item xs={7} sm={4} md={5} lg={6} xl={6.5}>
                <Box
                  sx={{
                    display: "flex",

                    justifyContent: "left",

                    mt: 0.7,

                    pb: 1,
                  }}
                >
                  <img src={logo} className="headerLogo" alt="" />
                </Box>
              </Grid>

              <Grid
                item
                xs={5}
                sm={8}
                md={7}
                lg={6}
                xl={5.5}
                sx={{
                  display: "flex",

                  justifyContent: "center",

                  alignItems: "center",
                }}
              >
                {/* for contact */}

                <Box
                  sx={{
                    width: "100%",

                    display: "flex",

                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: -0.5 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      {isPhoneScreen ? (
                        <a href="tel:9827832784">
                          <CallIcon
                            sx={{
                              fontSize: `${3.2 + FontSizeValue}rem`,

                              mt: 0.5,

                              mr: 0.3,

                              color: "#FF6600",
                            }}
                          />
                        </a>
                      ) : (
                        <CallIcon
                          sx={{
                            fontSize: "3.2rem",

                            mt: 0.5,

                            mr: 0.3,

                            color: "#FF6600",
                          }}
                        />
                      )}

                      <Box
                        sx={{
                          display: "flex",

                          alignItems: "center",

                          flexDirection: "column",

                          mt: 0.2,
                        }}
                      >
                        <Box sx={{ textAlign: "justify" }}>
                          <Typography
                            variant="p"
                            sx={{
                              color: "#161971",

                              fontSize: "1.2rem",

                              fontWeight: "500",

                              fontFamily: "Inter,sans-serif",
                            }}
                          >
                            {t("Customer Support")}
                          </Typography>

                          <br />

                          <Typography
                            variant="p"
                            sx={{
                              color: "#FF6600",

                              fontWeight: "600",

                              fontSize: "1.6rem",

                              fontFamily: "Inter,sans-serif",
                            }}
                          >
                            98278 32784
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {/* for font size controlller */}

                  {!isMediumScreen && (
                    <Box
                      sx={{
                        display: "flex",

                        alignItems: "center",

                        justifyContent: "space-around",

                        backgroundColor: "whitesmoke",

                        borderRadius: "10px",

                        width: "120px",

                        height: "40px",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          color: "#161971",

                          fontWeight: "400",

                          fontSize: "1.4rem",

                          color: "#1a1a1d",

                          m: 0.5,

                          cursor: "pointer",

                          fontFamily: "Inter,sans-serif",
                        }}
                        onClick={() => {
                          dispatch(decreaseFontSize());
                        }}
                      >
                        -A
                      </Typography>

                      <Typography
                        variant="p"
                        sx={{
                          color: "#161971",

                          fontWeight: "500",

                          fontSize: "1.6rem",

                          color: "#1a1a1d",

                          m: 0.5,

                          cursor: "pointer",

                          fontFamily: "Inter,sans-serif",
                        }}
                        onClick={() => {
                          dispatch(resetFontSize());
                        }}
                      >
                        A
                      </Typography>

                      <Typography
                        variant="p"
                        sx={{
                          fontWeight: "500",

                          fontSize: "1.8rem",

                          color: "#1a1a1d",

                          m: 0.5,

                          cursor: "pointer",

                          fontFamily: "Inter,sans-serif",
                        }}
                        onClick={() => {
                          dispatch(increaseFontSize());
                        }}
                      >
                        A+
                      </Typography>
                    </Box>
                  )}

                  {/* login and agent button */}

                  {!isMediumScreen && (
                    <Box
                      sx={{
                        display: "flex",

                        alignItems: "center",

                        justifyContent: "center",
                      }}
                    >
                      {/* <Button > */}

                      <FormControl
                        size="small"
                        sx={{
                          width: "auto",

                          // mr: 1,

                          mt: 0.1,

                          fontSize: "1.3rem",

                          zIndex: 0,

                          overflow: "auto",

                          mr: -0.8,

                          borderTopLeftRadius: "10px",

                          borderBottomLeftRadius: "10px",
                        }}

                        // disableScrollLock={true}
                      >
                        {/*   */}

                        <Select
                          defaultValue="Agent"
                          value={selectedOption}
                          onChange={handleChange}
                          sx={{
                            flex: "1",

                            color: "#ff6600",

                            fontSize: "1.5rem",

                            backgroundColor: "#FFF2EA",

                            fontWeight: "700",

                            zIndex: 1,

                            outline: "none",

                            "& .MuiSelect-icon": {
                              fontSize: "2rem",

                              color: "#FF6600",

                              opacity: "0.6",
                            },

                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#FFF2EA",
                            },

                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },

                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                          }}
                        >
                          <MenuItem
                            value={`Agent`}
                            sx={{
                              fontSize: "1.4rem",

                              "&:hover": {
                                backgroundColor: "#ff6600",
                              },
                            }}
                          >
                            {t("Agent")}
                          </MenuItem>

                          <MenuItem
                            value="option3"
                            sx={{
                              fontSize: "1.4rem",

                              "&:hover": {
                                backgroundColor: "#ff6600",
                              },
                            }}
                          >
                            {t("Distributor")}
                          </MenuItem>
                        </Select>
                      </FormControl>

                      {/* </Button> */}

                      <Button
                        variant="contained"
                        href="https://rt.sevenpay.in/login"
                        sx={{
                          backgroundColor: "#ff6600",
                          borderRadius: "10px",
                          fontSize: "1.5rem",
                          fontWeight: "600",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#FF6600",
                          },
                        }}
                      >
                        {t("Login")}
                      </Button>

                      {/* <img src={NewGif} style={{width:'80px',height:'40px',marginLeft:'10px'}}/> */}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>

          {isMediumScreen && (
            <Box
              sx={{
                float: "right",

                width: "48px",

                height: "48px",

                marginRight: "-10px",
              }}
            >
              <Box
                sx={{
                  width: "48px",

                  height: "48px",

                  backgroundColor: "#FFF2EF",

                  display: "flex",

                  justifyContent: "center",

                  alignItems: "center",

                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    display: "flex",

                    justifyContent: "center",

                    flexDirection: "column",
                  }}
                >
                  <PersonOutlineIcon sx={{ fontSize: "2.5rem" }} />

                  <Typography
                    component="a"
                    href="https://rt.sevenpay.in/login"
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: "500",
                      textDecoration: "none", // Optional: Remove underline
                      color: "inherit", // Ensure the text inherits color from the parent <a>
                      "&:hover": {
                        textDecoration: "underline", // Optional: Add underline on hover
                      },
                    }}
                  >
                    LOGIN
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {!isMediumScreen && (
        <Box sx={{ position: "relative" }}>
          {" "}
          <Nav />
        </Box>
      )}

      {isMediumScreen && (
        <NavBar setOpenMenu={setOpenMenu} openMenu={openMenu} />
      )}
    </Box>
  );
};

export default Header;
