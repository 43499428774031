import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Box,
  Paper,
  Divider,
  useMediaQuery,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { constant } from "../Constant/const";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Nav = () => {
  const isTabScreen = useMediaQuery("(max-width:1200px)");
  const FontSizeValue = useSelector((state) => state.reducer.Style.fontSize);
  const { t, i18n } = useTranslation();
  const C_Language =
    (i18n.language === "en" && "English") ||
    (i18n.language === "hn" && "हिंदी") ||
    (i18n.language === "odi" && "ଓଡିଆ") ||
    (i18n.language === "ben" && "বাংলা");

  const navigate = useNavigate();
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [LanguageanchorEl, setLanguageAnchorEl] = useState(null);
  // const [LanguageValue, setLanguageValue] = useState(C_Language);
  const [LanguageData, SetLanguageData] = useState([...constant?.Language]);

  const fontStyle = {
    fontSize: "1.6rem !Important",
    fontWeight: "600",
    fontFamily: "Inter,sans-serif",
    textTransform: "capitalize",
    overflow: "hidden",
    ml: 1,
    letterSpacing: "-.3px",
  };

  const subMenuStyle = {
    fontSize: `${(1.4 + FontSizeValue).toFixed(2)}rem  !important`,
    fontWeight: "400",
    height: "38px",
    fontFamily: "Inter,sans-serif",
    pl: 3,
    overflow: "hidden",
    minWidth: "200px",
    m: -1,
    pt: 3,
    pb: 3,
    "&:hover": {
      backgroundColor: "#FF6600",
      color: "#fff",
    },
  };
  console.log(`${(1.4 + FontSizeValue).toFixed(2)}rem`, "language");
  const handleMenuOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  // ----: ANIL CODE :-----
  const handleMenuClose1 = (name) => {
    setAnchorEl1(null);
    if (name === "Banking Service") {
      // setTimeout(() => {
      navigate("/BankingServices");
      window.scrollTo({ top: 0 });

      // setAnchorEl1(null);
      // }, 250);
    } else if (name === "Payment Service") {
      // setTimeout(() => {
      navigate("/PaymentServices");
      window.scrollTo({ top: 0 });

      // setAnchorEl1(null);
      // }, 250);
    } else if (name === "Utility Service") {
      // setTimeout(() => {
      navigate("/utility.html");
      window.scrollTo({ top: 0 });

      // setAnchorEl1(null);
      // }, 250);
    } else if (name === "Devices") {
      navigate("/Devices");
      window.scrollTo({ top: 0 });
    } else if (name === "Travel Service") {
      navigate("/travelService");
      window.scrollTo({ top: 0 });
    } else if (name === "Partner Service") {
      navigate("/partnerService");
      window.scrollTo({ top: 0 });
    }
  };

  // ###########################

  // const handleMenuOpen2 = (event) => {
  //   setAnchorEl2(event.currentTarget);
  // };

  // const handleMenuClose2 = () => {
  //   setAnchorEl2(null);
  // };

  const handleMenuOpen3 = (event) => {
    setAnchorEl3(event.currentTarget);
    // navigate("/About");
    // dispatch(showAbout())
  };

  const handleMenuClose3 = (name) => {
    setAnchorEl3(null);
    // navigate("/About");
    window.scrollTo({ top: 0 });

    if (name === "About us") {
      navigate("/AboutUs");
    } else if (name === "Mission/Vision") {
      navigate("/MissionAndVision");
    } else if (name === "Management Team") {
      navigate("/Management");
    }
  };

  const handleLanguageOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = (val, name) => {
    setLanguageAnchorEl(null);
    if (val && name) {
      localStorage.setItem("Language", val);
      i18n.changeLanguage(val);
      // setLanguageValue(name);
    }
  };

  // console.log(anchorEl, "anker value");

  return (
    <Paper
      className="sub-header"
      elevation={0}
      sx={{
        // mt: -0.5,
        height: "7.5vh",
        width: " 100%",
        position: "fixed",
        top: "48px",
        zIndex: "1",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: isTabScreen ? "90vw" : "75vw" }}>
          <AppBar
            position="static"
            elevation={0}
            sx={{ color: "#1a1a1d", backgroundColor: "transparent" }}
          >
            <Toolbar sx={{ justifyContent: "space-between" }}>
              {/* <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
        <MenuIcon />
      </IconButton> */}

              <Box>
                <Button
                  color="inherit"
                  sx={fontStyle}
                  onClick={() => {
                    navigate("/");
                    window.scrollTo({ top: 0 });
                  }}
                  disableRipple
                >
                  {t("Home")}
                </Button>
                {/*services  */}
                <Button
                  color="inherit"
                  sx={fontStyle}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={handleMenuOpen1}
                  disableRipple
                >
                  {t("Services")}
                </Button>
                <Menu
                  anchorEl={anchorEl1}
                  open={Boolean(anchorEl1)}
                  onClose={handleMenuClose1}
                  elevation={0}
                  anchorOrigin={{
                    vertical: "bottom",

                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",

                    horizontal: "center",
                  }}
                  PaperProps={{
                    sx: {
                      boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  disableScrollLock={true}
                >
                  <MenuItem
                    onClick={() => handleMenuClose1("Banking Service")}
                    sx={subMenuStyle}
                  >
                    {t("Banking Services")}
                  </MenuItem>
                  <Divider sx={{ ml: 1.5, mr: 1.5 }} />
                  <MenuItem
                    onClick={() => handleMenuClose1("Payment Service")}
                    sx={subMenuStyle}
                  >
                    {t("Payment Services")}
                  </MenuItem>
                  <Divider sx={{ ml: 1.5, mr: 1.5 }} />
                  <MenuItem
                    onClick={() => handleMenuClose1("Utility Service")}
                    sx={subMenuStyle}
                  >
                    {t("Utility Payments")}
                  </MenuItem>
                  <Divider sx={{ml:1.5,mr:1.5}} />
                  <MenuItem
                    onClick={() => handleMenuClose1("Devices")}
                    sx={subMenuStyle}
                  >
                    Devices
                  </MenuItem>
                {/* <Divider sx={{ml:1.5,mr:1.5}} />
                  <MenuItem
                    onClick={() => handleMenuClose1("Travel Service")}
                    sx={subMenuStyle}
                  >
                    Travel
                  </MenuItem>
                  <Divider sx={{ml:1.5,mr:1.5}} />
                  <MenuItem
                    onClick={() => handleMenuClose1("Partner Service")}
                    sx={subMenuStyle}
                  >
                    Partner Services
                  </MenuItem>  */}
                </Menu>
                {/* media Event  for now content is not ready when content ready jus un comment the button and menu*/}
                {/* <Button
                  color="inherit"
                  sx={fontStyle}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={handleMenuOpen2}
                >
                  {t("Media & Event")}
                </Button>
                <Menu
                  anchorEl={anchorEl2}
                  open={Boolean(anchorEl2)}
                  onClose={handleMenuClose2}
                  elevation={0}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{ overflow: "hidden" }}
                  PaperProps={{
                    sx: {
                      boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  disableScrollLock={true}
                >
                  <MenuItem onClick={handleMenuClose2} sx={subMenuStyle}>
                    {t("Press Conferences")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose2} sx={subMenuStyle}>
                    {t("Product Launches")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose2} sx={subMenuStyle}>
                    {t("Award Ceremonies")}
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose2} sx={subMenuStyle}>
                    {t("Photo Gallery")}
                  </MenuItem>
                </Menu> */}
                {/* About us */}

                <Button
                  color="inherit"
                  sx={fontStyle}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={handleMenuOpen3}
                  disableRipple
                >
                  {t("Who We Are")}
                </Button>
                <Menu
                  anchorEl={anchorEl3}
                  open={Boolean(anchorEl3)}
                  onClose={handleMenuClose3}
                  elevation={0}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{ overflow: "hidden" }}
                  PaperProps={{
                    sx: {
                      boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                  disableScrollLock={true}
                >
                  <MenuItem
                    onClick={() => {
                      handleMenuClose3("About us");
                    }}
                    sx={subMenuStyle}
                  >
                    {t("About us")}
                  </MenuItem>
                  {/* <Divider sx={{ ml: 1.5, mr: 1.5 }} /> */}
                  {/* <MenuItem
                    onClick={() => {
                      handleMenuClose3("Management Team");
                    }}
                    sx={subMenuStyle}
                  >
                    {t("Management Team")}
                  </MenuItem> */}
                  <Divider sx={{ ml: 1.5, mr: 1.5 }} />
                  <MenuItem
                    onClick={() => {
                      handleMenuClose3("Mission/Vision");
                    }}
                    sx={subMenuStyle}
                  >
                    {t("Mission/Vision")}
                  </MenuItem>
                </Menu>
                <Button
                  color="inherit"
                  sx={fontStyle}
                  onClick={() => {
                    navigate("/Carrer");
                    window.scrollTo({ top: 0 });
                  }}
                  disableRipple
                >
                  {t("Careers")}
                </Button>
                <Button
                  color="inherit"
                  sx={fontStyle}
                  onClick={() => {
                    navigate("/Contact");
                    window.scrollTo({ top: 0 });
                  }}
                  disableRipple
                >
                  {t("Contact Us")}
                </Button>
              </Box>
              <Button
                sx={{
                  color: "gray",
                  fontSize: "1.2rem",
                  textTransform: "capitalize",
                }}
                endIcon={<ArrowDropDownIcon sx={{ ml: -0.4, mt: -0.3 }} />}
                onClick={handleLanguageOpen}
                disableRipple
              >
                {t("Language")} : {C_Language}
              </Button>
              <Menu
                anchorEl={LanguageanchorEl}
                open={Boolean(LanguageanchorEl)}
                onClose={() => handleLanguageClose(false, false)}
                elevation={0}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  sx: {
                    boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.2)",
                  },
                }}
                disableScrollLock={true}
              >
                {LanguageData.map((ele) => (
                  <MenuItem
                    key={ele.id}
                    onClick={() => handleLanguageClose(ele.value, ele.name)}
                    sx={{ ...subMenuStyle, margin: 0.5 }}
                  >
                    {ele.name}
                  </MenuItem>
                ))}
              </Menu>
            </Toolbar>
          </AppBar>
        </Box>
      </Box>
    </Paper>
  );
};

export default Nav;
