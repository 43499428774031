
import i18n from "i18next";

import { initReactI18next } from "react-i18next";


// Importing translation files

import translationEN from "./locales/en/translation.json";
import translationHE from "./locales/hn/translation.json";
import translationODI from './locales/odi/translation.json'
import translationBEN from './locales/ben/translation.json'
//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  hn: {
    translation: translationHE,
  },
  odi: {
    translation: translationODI
  },
  ben:{
    translation: translationBEN
  }
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      hn: {
        translation: translationHE,
      },
      odi: {
        translation: translationODI
      },
      ben:{
        translation: translationBEN
      }
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;