import React,{useEffect} from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
const PrivateRoute = ({ children }) => {
  
  return (
    <>
     
      <div 
    //   style={{ minHeight: "85.3vh" }}
      >{children}</div>
      <Footer />
    </>
  );
};
export default PrivateRoute;
