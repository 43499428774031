export const constant = {
  Language: [
    { id: 1, name: "English", active: true, value: "en" },
    // { id: 2, name: "हिंदी",active:false,value:'hn' },
    // { id: 3, name: "ଓଡିଆ",active:false,value:'odi' },
    // { id: 4, name: "বাংলা" ,active:false,value:'ben'},

    // { id: 5, name: "తెలుగు",active:false },
    // { id: 6, name: "ગુજરાતી",active:false },
    // { id: 7, name: "മലയാളം" ,active:false},
    // { id: 8, name: "मराठी" ,active:false},
    // { id: 9, name: "தமிழ்" ,active:false},
  ],
};
